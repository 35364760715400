<template>
    <CHeader with-subheader>
        <CToggler in-header class="ml-3 d-lg-none" @click="$store.commit('toggleSidebarMobile')"/>
        <CToggler in-header class="ml-3 d-md-down-none" @click="$store.commit('toggleSidebarDesktop')"/>
        <CHeaderBrand class="mx-auto d-lg-none" src="img/brand/coreui-vue-pro-logo.svg" width="190" height="46" alt="CoreUI Logo"/>
        <CHeaderNav class="d-md-down-none mr-auto">
            <CHeaderNavItem class="px-3">
                <CHeaderNavLink :to="`/${this.$store.getters['getOrganizationName']}/dashboard`">
                    Dashboard
                </CHeaderNavLink>
            </CHeaderNavItem>
        </CHeaderNav>
        <CHeaderNav>
            <CHeaderNavItem class="px-2">
                <button @click="() => $store.commit('toggle', 'darkMode')" class="c-header-nav-btn">
                    <CIcon v-if="$store.state.darkMode" name="cil-sun"/>
                    <CIcon v-else name="cil-moon"/>
                </button>
            </CHeaderNavItem>
            
            
            <CHeaderNavItem class="px-2">
                <button class="c-header-nav-btn" @click="$store.commit('toggle', 'asideShow')" >
                    <CIcon size="lg" name="cil-applications-settings" class="mr-2"/>
                    <CBadge shape="pill" color="danger" v-if="header_notification_counter > 0">{{header_notification_counter}}</CBadge>
                </button>
            </CHeaderNavItem>

            
            <CHeaderNavItem class="px-2">
                <TheHeaderDropdownAccnt/>
            </CHeaderNavItem>
        </CHeaderNav>
    
        <CSubheader class="px-3">
            <CBreadcrumbRouter class="border-0 mb-0"/>
        </CSubheader>
    </CHeader>
</template>

<script>
import TheHeaderDropdownAccnt from './TheHeaderDropdownAccnt'

export default {
    name: 'TheHeader',
    components: {
        TheHeaderDropdownAccnt
    },
    data() {
        return {
            header_notification_counter: 0,
        }
    }

}
</script>
